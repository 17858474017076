import { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Typography } from 'base-components';

const styles = {};

class TestResultPage extends Component {
  render() {
    return <Typography> Results go here </Typography>;
  }
}

export default withStyles(styles)(TestResultPage);
