import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CancelButton, ContainedButton } from '@onc/composite-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'base-components';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';

class TestDeleteDialog extends PureComponent {
  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
    deleteNode: PropTypes.func.isRequired,
    showDialog: PropTypes.bool.isRequired,
    node: NodePropType,
  };

  static defaultProps = {
    node: undefined,
  };

  removeNode = async () => {
    const { closeDialog, deleteNode } = this.props;
    await deleteNode();
    closeDialog();
  };

  render() {
    const { node, showDialog, closeDialog } = this.props;
    if (node) {
      return (
        <Dialog open={showDialog} onClose={closeDialog}>
          <DialogTitle id="delete-title">Delete Test Tree Node?</DialogTitle>
          <DialogContent>
            Are you sure you want to delete &quot;
            {node.testTreeNode.testTreeNodeName}&quot;?
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={closeDialog} />
            <ContainedButton
              translationKey="automationTesting.deleteNode"
              onClick={this.removeNode}
            />
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  }
}

export default TestDeleteDialog;
