import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ContainedButton } from '@onc/composite-components';
import { MenuItem } from 'base-components';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';
import TestTreeNodeService from 'domain/Apps/automation-testing/TestTreeNodeService';
import Panel from 'library/CompositeComponents/panel/Panel';
import TestTree from './TestTree';

const styles = {
  runButton: {
    float: 'right',
  },
};

class TestTreePanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    createNode: PropTypes.func.isRequired,
    selectNode: PropTypes.func.isRequired,
    selectedNode: NodePropType,
    treeData: NodePropType,
    onError: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selectedNode: undefined,
    treeData: undefined,
  };

  state = { runClicked: false, runId: undefined };

  executeTestTreeNode = async () => {
    const { selectedNode, onError } = this.props;
    this.setState({ runClicked: true });
    try {
      const data = await TestTreeNodeService.execute(
        selectedNode.testTreeNode.testTreeNodeId
      );
      this.setState({ runId: data.testRunId });
    } catch (error) {
      const treeData = { children: [] };
      this.setState({ treeData });
      onError(error);
    }
  };

  renderMenu() {
    const { createNode } = this.props;
    return <MenuItem onClick={createNode}>Create New Test</MenuItem>;
  }

  render() {
    const { classes, selectedNode, selectNode, treeData } = this.props;
    const { runClicked, runId } = this.state;

    let translationKey = 'testTree.runTest';
    if (selectedNode && selectedNode.testTreeNode.testTypeId === 1) {
      translationKey = 'testTree.runGroup';
    }

    return (
      <Panel title="Test Tree" menu={treeData ? this.renderMenu() : undefined}>
        <TestTree
          treeData={treeData}
          selectedNode={selectedNode}
          selectNode={selectNode}
        />
        <ContainedButton
          disabled={!selectedNode || runClicked}
          className={classes.runButton}
          onClick={() => this.executeTestTreeNode()}
          translationKey={translationKey}
        />
        {runClicked && runId && (
          <Redirect push to={`/AutomationTesting/run/${runId}`} />
        )}
      </Panel>
    );
  }
}

export default withStyles(styles)(TestTreePanel);
