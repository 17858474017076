import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from 'base-components';
import TestTypeDropdown from 'domain/AppComponents/dropdowns/TestTypeDropdown';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';
import Panel from 'library/CompositeComponents/panel/Panel';
import TestDeleteDialog from './TestDeleteDialog';

const styles = (theme) => ({
  container: {
    margin: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
});

class TaskInformationPanel extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    onFieldChange: PropTypes.func.isRequired,
    updateNode: PropTypes.func.isRequired,
    deleteNode: PropTypes.func.isRequired,
    node: NodePropType,
  };

  static defaultProps = {
    node: undefined,
  };

  state = {
    testNodeName: 'File Archiver',
    showDialog: false,
  };

  onFieldChange = (field) => async (event) => {
    const { onFieldChange } = this.props;
    await onFieldChange(event.target.value, field);
  };

  selectTestType = async (event) => {
    const { onFieldChange, updateNode } = this.props;
    await onFieldChange(event.target.value, 'testTypeId');
    updateNode();
  };

  hideDialog = () => {
    this.setState({ showDialog: false });
  };

  showDialog = () => {
    this.setState({ showDialog: true });
  };

  isTestTypeDisabled() {
    const { node } = this.props;
    return node.children.length > 0;
  }

  renderNodeInformation() {
    const { classes, node, updateNode } = this.props;
    if (node) {
      return (
        <>
          <TestTypeDropdown
            className={classes.textField}
            disabled={this.isTestTypeDisabled(node)}
            initialValue="Select..."
            fullWidth
            value={node.testTreeNode.testTypeId}
            title="Module"
            onChange={this.selectTestType}
          />
          <TextField
            translationKey="common.textfields.name"
            classes={{ textField: classes.textField }}
            fullWidth
            value={node.testTreeNode.testTreeNodeName}
            onBlur={updateNode}
            onChange={this.onFieldChange('testTreeNodeName')}
          />
          <TextField
            translationKey="common.textfields.description"
            classes={{ textField: classes.textField }}
            fullWidth
            value={node.testTreeNode.description}
            onBlur={updateNode}
            onChange={this.onFieldChange('description')}
            multiline
            rows={2}
          />
        </>
      );
    }
    return null;
  }

  renderOptionsMenu() {
    const { node } = this.props;
    if (node) {
      return <MenuItem onClick={this.showDialog}>Delete...</MenuItem>;
    }
    return null;
  }

  renderPanelTitle() {
    const { node } = this.props;
    return node
      ? `Test Tree Node Id: ${node.testTreeNode.testTreeNodeId}`
      : `Select a Node...`;
  }

  render() {
    const { classes, node, deleteNode } = this.props;
    const { showDialog } = this.state;
    return (
      <Panel title={this.renderPanelTitle()} menu={this.renderOptionsMenu()}>
        <div className={classes.container}>
          {this.renderNodeInformation()}
          <TestDeleteDialog
            node={node}
            deleteNode={deleteNode}
            showDialog={showDialog}
            closeDialog={this.hideDialog}
          />
        </div>
      </Panel>
    );
  }
}

export default withStyles(styles)(TaskInformationPanel);
