import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from '@mui/material/styles';
import { withTheme } from '@mui/styles';
import PropTypes from 'prop-types';

const TaskAutomationThemeProvider = ({ children = undefined }) => {
  const automationTheme = (outerTheme) =>
    createTheme(
      adaptV4Theme({
        ...outerTheme,
        props: {
          MuiFilledInput: {
            margin: 'dense',
          },
        },
      })
    );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={automationTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

TaskAutomationThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default withTheme(TaskAutomationThemeProvider);
