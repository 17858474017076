import { PureComponent } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { ExpandMore, ChevronRight } from '@onc/icons';
import { CircularProgress, TreeItem, TreeView } from 'base-components';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';

import TestTreeItem from './TestTreeItem';

const styles = (theme) => ({
  loadingContainer: {
    margin: `auto`,
    height: `100px`,
  },
  loading: {
    display: 'block',
    margin: 'auto',
  },
  tree: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  root: {
    '&:focus > $content': {
      backgroundColor: '#FFF',
    },
    '&:hover > $content': {
      backgroundColor: '#FFF',
    },
  },
  content: {},
});

class TestTree extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    selectNode: PropTypes.func.isRequired,
    selectedNode: NodePropType,
    treeData: NodePropType,
  };

  static defaultProps = {
    selectedNode: undefined,
    treeData: undefined,
  };

  state = {
    expandedNodes: [],
  };

  renderTestTree = (treeData) => {
    const { selectNode, selectedNode, classes } = this.props;
    const { expandedNodes } = this.state;
    return treeData.map((node) => (
      <TreeItem
        key={node.testTreeNode.testTreeNodeId}
        nodeId={`${node.testTreeNode.testTreeNodeId}`}
        label={
          <TestTreeItem
            node={node}
            expandedNodes={expandedNodes}
            selectNode={selectNode}
            selectedNode={selectedNode}
          />
        }
        classes={{
          root: classes.root,
          content: classes.content,
        }}
      >
        {this.renderTestTree(node.children)}
      </TreeItem>
    ));
  };

  handleExpand = (event, nodes) => {
    this.setState({ expandedNodes: nodes });
  };

  render() {
    const { expandedNodes } = this.state;
    const { classes, treeData } = this.props;
    if (!treeData) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </div>
      );
    }
    return (
      <TreeView
        defaultEndIcon={<div style={{ width: 24 }} />}
        defaultCollapseIcon={<ExpandMore fontSize="small" />}
        defaultExpandIcon={<ChevronRight fontSize="small" />}
        className={classes.tree}
        expanded={expandedNodes}
        onNodeToggle={this.handleExpand}
      >
        {this.renderTestTree(treeData.children)}
      </TreeView>
    );
  }
}

export default withStyles(styles)(TestTree);
