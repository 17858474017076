import AutomationWebRequest from './AutomationWebRequest';

const TestTreeNodeService = {
  getAll: async () => {
    const response = await AutomationWebRequest.get(
      'api/jsonws/automation.testtreenode',
      'get-test-tree'
    );
    return AutomationWebRequest.parseAPIResponse(response);
  },

  get: async (id) => {
    const response = await AutomationWebRequest.get(
      'api/jsonws/automation.testtreenode',
      'get-test-tree-node',
      { testTreeNodeId: id }
    );
    return AutomationWebRequest.parseAPIResponse(response);
  },

  create: async (params) => {
    const response = await AutomationWebRequest.get(
      'api/jsonws/automation.testtreenode',
      'create',
      params
    );
    return AutomationWebRequest.parseAPIResponse(response);
  },

  update: async (node) => {
    const response = await AutomationWebRequest.get(
      'api/jsonws/automation.testtreenode',
      'update',
      node
    );
    return AutomationWebRequest.parseAPIResponse(response);
  },

  delete: async (id) => {
    const response = await AutomationWebRequest.get(
      'api/jsonws/automation.testtreenode',
      'delete',
      { testTreeNodeId: id }
    );
    return AutomationWebRequest.parseAPIResponse(response);
  },

  execute: async (id) => {
    const response = await AutomationWebRequest.get(
      'o/rest/darjeeling',
      `run/testTreeNodeId/${id}`
    );
    return AutomationWebRequest.parseAPIResponse(response);
  },
};

export default TestTreeNodeService;
