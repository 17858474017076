import { axios } from '@onc/service';

/** Make HTTP GET query to the liferay automation machine */
//
class AutomationWebRequest {
  /** Get the URL of Automation from ONC_BASE_URL or ONC_ENV. */
  static getBaseUrl() {
    if (process.env.ONC_BASE_URL) {
      return process.env.ONC_BASE_URL;
    }
    if (process.env.ONC_ENV && process.env.ONC_ENV === 'TEST') {
      return 'http://qalr3.onc.uvic.ca:8080';
    }
    return `http://${window.location.hostname}:8080`;
  }

  static instance = axios.create({
    baseURL: AutomationWebRequest.getBaseUrl(),
  });

  /**
   * Make a GET request
   *
   * @param {string} resource - The resource associated to the api request
   * @param {string} method - The method of the api request
   * @param {object} params - Query params
   * @param {object} config - Axios config object
   * @returns {Promise}
   */
  static get(resource, method, params, config) {
    return AutomationWebRequest.instance.request({
      method: 'get',
      url: AutomationWebRequest.buildURL(resource, method),
      params,
      ...config,
    });
  }

  /**
   * Make a GET request
   *
   * @param {string} resource - The resource associated to the api request
   * @param {string} method - The method of the api request
   */
  static buildURL(resource, method) {
    return `${AutomationWebRequest.getBaseUrl()}/${resource}/${method}/`;
  }

  /** Handle a promise returned from a request to an Liferay's jsonws services */
  static parseAPIResponse(response) {
    if (response.status !== 200) {
      throw new Error(response.data.error.message);
    }
    return response.data;
  }
}

export default AutomationWebRequest;
