import { PureComponent } from 'react';

import { Box } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Folder, Description, FolderOpen } from '@onc/icons';
import { Typography } from 'base-components';
import NodePropType from 'domain/Apps/automation-testing/NodePropType';

const styles = (theme) => ({
  testIcon: {
    marginRight: theme.spacing(1),
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
});

class TestTreeItem extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    selectNode: PropTypes.func.isRequired,
    expandedNodes: PropTypes.arrayOf(PropTypes.number).isRequired,
    node: NodePropType.isRequired,
    selectedNode: NodePropType,
  };

  static defaultProps = {
    selectedNode: undefined,
  };

  renderNodeIcon = (node) => {
    const { classes, expandedNodes } = this.props;
    // If test type is not 1, that means it is a test, not a folder
    if (node.testTreeNode.testTypeId !== 1) {
      return (
        <Description
          className={classes.testIcon}
          color="disabled"
          fontSize="small"
        />
      );
    }
    // Renders the open folder icon if the tree item is expanded or has no children
    if (
      node.children.length === 0 ||
      expandedNodes.some((id) => node.testTreeNode.testTreeNodeId === id)
    ) {
      return (
        <FolderOpen
          className={classes.testIcon}
          color="primary"
          fontSize="small"
        />
      );
    }
    // Renders folder by default
    return (
      <Folder className={classes.testIcon} color="primary" fontSize="small" />
    );
  };

  isSelected = () => {
    const { selectedNode, node } = this.props;
    return (
      selectedNode &&
      node.testTreeNode.testTreeNodeId ===
        selectedNode.testTreeNode.testTreeNodeId
    );
  };

  renderName = () => {
    const { selectedNode, node } = this.props;
    return this.isSelected()
      ? selectedNode.testTreeNode.testTreeNodeName
      : node.testTreeNode.testTreeNodeName;
  };

  render() {
    const { node, selectNode, classes } = this.props;
    return (
      <Box
        style={{ position: 'relative' }}
        display="flex"
        onClick={(e) => selectNode(e, node)}
        className={this.isSelected() ? classes.selected : null}
      >
        {this.renderNodeIcon(node)}
        <Typography variant="body2">{this.renderName()}</Typography>
      </Box>
    );
  }
}

export default withStyles(styles)(TestTreeItem);
