import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';
import TestResultPage from './test-result-page/TestResultPage';
import TestTreePage from './test-tree-page/TestTreePage';

const styles = {};

class AutomationTesting extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    onError: PropTypes.func.isRequired,
    onInfo: PropTypes.func.isRequired,
  };

  renderTestResults = (routerProps) => <TestResultPage {...routerProps} />;

  renderTestTree = (routerProps) => {
    const { onError, onInfo } = this.props;
    return <TestTreePage onError={onError} onInfo={onInfo} {...routerProps} />;
  };

  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={match.path} render={this.renderTestTree} />
        <Route path={`${match.path}/run/:id`} render={this.renderTestResults} />
      </Switch>
    );
  }
}

export default withStyles(styles)(withSnackbars(AutomationTesting));
