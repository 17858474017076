import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'base-components';

class TestTypeDropdown extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    initialValue: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    className: undefined,
  };

  getTestTypes = () => [
    { label: 'Folder', value: 1, key: '1' },
    { label: 'Web Service Test', value: 2, key: '2' },
    { label: 'Reprocess Test', value: 3, key: '3' },
    { label: 'DAF Test', value: 4, key: '4' },
    { label: 'Data Search', value: 5, key: '5' },
    { label: 'Task Test', value: 6, key: '6' },
  ];

  render() {
    const { title, initialValue, className } = this.props;
    return (
      <Dropdown
        label="Test Type"
        className={className}
        options={this.getTestTypes()}
        title={title}
        initialValue={initialValue}
        {...this.props}
      />
    );
  }
}
export default TestTypeDropdown;
