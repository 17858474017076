import { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Grid } from 'base-components';
import TestInformationPanel from 'domain/AppComponents/automation-testing/test-information-panel/TestInformationPanel';
import TestTreePanel from 'domain/AppComponents/automation-testing/test-tree-panel/TestTreePanel';
import TestTreeNodeService from '../TestTreeNodeService';
import TaskAutomationThemeProvider from '../theme/TaskAutomationThemeProvider';

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  grid: {
    padding: theme.spacing(1),
  },
});

class TestTreePage extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
      grid: PropTypes.string,
    }),
    onInfo: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
  };

  state = {
    selectedNode: undefined,
    treeData: undefined,
  };

  static defaultProps = {
    classes: undefined,
  };

  componentDidMount() {
    this.getTestTree();
  }

  getTestTree = async () => {
    const { onError } = this.props;
    try {
      const treeData = await TestTreeNodeService.getAll();
      this.setState({ treeData });
    } catch (error) {
      const treeData = { children: [] };
      this.setState({ treeData });
      onError(error);
    }
  };

  onFieldChange = async (value, stateKey) => {
    const { selectedNode } = this.state;
    const updatedNode = {
      ...selectedNode,
      testTreeNode: {
        ...selectedNode.testTreeNode,
        [stateKey]: value,
      },
    };
    this.setState({ selectedNode: updatedNode });
  };

  createNode = async () => {
    const { onInfo, onError } = this.props;
    const { selectedNode } = this.state;
    let params = {
      parentTestTreeNodeId: 0,
      sequenceNo: 1,
    };
    if (selectedNode) {
      let parentId = selectedNode.testTreeNode.testTreeNodeId;
      if (selectedNode.testTreeNode.testTypeId !== 1) {
        parentId = selectedNode.testTreeNode.parentTestTreeNodeId;
      }
      params = {
        parentTestTreeNodeId: parentId,
        sequenceNo: selectedNode.children.length + 1,
      };
    }
    try {
      const newNode = await TestTreeNodeService.create(params);
      onInfo(`Test Tree Node ${newNode.testTreeNodeId} created`);
      this.setState({
        selectedNode: {
          children: [],
          testTreeNode: newNode,
        },
      });
      this.getTestTree();
    } catch (error) {
      onError(error);
    }
  };

  deleteNode = async () => {
    const { onInfo, onError } = this.props;
    const { selectedNode } = this.state;
    try {
      const response = await TestTreeNodeService.delete(
        selectedNode.testTreeNode.testTreeNodeId
      );
      onInfo(response.message);
      this.setState({
        selectedNode: undefined,
      });
      this.getTestTree();
    } catch (error) {
      onError(error);
    }
  };

  updateNode = async () => {
    const { onInfo, onError } = this.props;
    const { selectedNode } = this.state;
    try {
      const updatedNode = await TestTreeNodeService.update(
        selectedNode.testTreeNode
      );
      onInfo(`Test Tree Node ${updatedNode.testTreeNodeId} updated`);
      this.getTestTree();
    } catch (error) {
      onError(error);
    }
  };

  selectNode = async (event, node) => {
    const { onError } = this.props;
    event.stopPropagation();
    event.preventDefault();
    try {
      const data = await TestTreeNodeService.get(
        node.testTreeNode.testTreeNodeId
      );
      this.setState({
        selectedNode: {
          testTreeNode: data.testTreeNode,
          attributes: data.attributes,
          children: node.children,
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  render() {
    const { classes, onInfo, onError } = this.props;
    const { selectedNode, treeData } = this.state;
    return (
      <TaskAutomationThemeProvider className={classes.root}>
        <Grid container className={classes.grid}>
          <Grid item xs={false} lg={1} xl={2} />
          <Grid item xs={12} lg={10} xl={8}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TestTreePanel
                  treeData={treeData}
                  selectedNode={selectedNode}
                  selectNode={this.selectNode}
                  createNode={this.createNode}
                  onInfo={onInfo}
                  onError={onError}
                />
              </Grid>
              <Grid item xs={7}>
                <TestInformationPanel
                  node={selectedNode}
                  updateNode={this.updateNode}
                  onFieldChange={this.onFieldChange}
                  deleteNode={this.deleteNode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={false} lg={1} xl={2} />
        </Grid>
      </TaskAutomationThemeProvider>
    );
  }
}

export default withStyles(styles)(TestTreePage);
